import * as React from 'react'
import Layout from '../component/layout'
import { StaticImage } from 'gatsby-plugin-image'
import image1 from '../images/image-asset1.webp'
import {Link} from 'gatsby'

const SAFE_SPACE_DEFINITIONS = [
    {
        title: 'Definition',
        body: 'A place / environment in which a person / category of people can feel confident that they will not be exposed to discrimination, criticism, harassment, or any other emotional / physical harm.'
    },
    {
        title: 'Wiki',
        body: 'Historically, safe spaces / safer spaces / positive spaces were used to indicate that a teacher, educational institution, or student body did not tolerate anti-LGBT violence, harassment, or hate speech, thereby creating a safe place for all LGBT students. The term "safe space" has been extended to refer to an autonomous space for individuals who feels marginalized (extending to all forms of discrimination), to come together to communicate their experiences of marginalization, typically on a university campus.'
    },
    {
        title: 'Autonomous Space',
        body: 'People <span class="font-sylexiad-bold">can</span> be excluded from safe spaces if their actions/behavior prevents safe space to be conducted properly. Additionally, they can be excluded if their presence alone traumatizes others.'
    },
    {
        title: 'Confidentiality',
        body:  `Everyone should feel that they are able to confide in one another. All privacy must be respected and all information can only be divulged with the owner's explicit permission. Anyone who feels their privacy threatened should inform the organizer. "Everything said remains in the space." Exceptions: notions to self-harm, exploit, or abuse to oneself and /or others. Also, confidentiality does not extend to name or recognition, but to each person's personal background, narratives, and experiences.`
    },
    {
        title: 'Respect',
        body: 'Everyone should feel and recognize the basic worth and dignity as a human being. We respect others by actively listening (listening to understand), avoiding quick judgment and prejudgment (prejudice), and treating others as equal adults (I am OK, You are OK). That said, respecting others can help build our own self-respect, and thus moments of offense, understanding, and sensibilities are fluid and developmental. It is an ever learning skill that guides our body language, words, actions, eye contact, etc.'
    },
    {
        title: 'Growth',
        body: `Everyone should walk away feeling that they have grown and/or learnt something relevant. Appreciation and affirmation of each other are important: we accept and add to each other's experience, not to put us and our own experiences down. Sharing and revealing vulnerabilities to others with your story and perspective, helps; instead of assuming that everyone feels the same way as you do. (Making "I feel" and "I believe" statements.) Reciprocate and foster an environment of mutual care.`
    }
]

const AboutUs = () => {
    return (
        <Layout title={'About Us | YouYou'}>
            <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-2 gap-4 justify-center items-stretch m-3 md:m-10'>
                {/* row: 1 col on small screen, 2 cols on bigger screens */}
                <div className='col-span-1 md:col-span-2 font-sylexiad'>
                    <h1 className='title'>About YouYou</h1>
                    <p className='text text-xl'>
                    Founded in 2018, YouYou is a community committed to the safe space of the religious minority and the LGBTQIA+. We started as a cellgroup-like fellowship, which consisted of members of unique queer backgrounds and faith/non-faith cultures. We were encouraged by past instances of interfaith activities in Singapore, internationally, and globally;  and we sought to ask ourselves: How can Christians, Muslims, Jews, Buddhists, Taoists, Confucians, Baha’i, Pagans, Wiccans, Agnostics, Humanists, and Atheists (and ALL others) walk together in the spirit of love and affirmation, in our shared humanity and spirituality? Can we relate our faith and identities to each other? Can we get along?
                    <br /><br />Yes, we can! We’ve been doing so in YouYou, and it is possible to relate our experiences and affirm each other in spiritual plurality. If we make a safe space where everyone feels secure, that is. 
                    <br /><br />Listening to the narratives within the queer community (and each other) REALLY helped us understand what is it like to be a minority and singled out. As a result, in addition to being affirmative to interfaith and LGBTQ+ activities, we are aware of the institutional exclusion, abuse, and harm done to individuals who were simply being themselves. Here in YouYou, we welcome all orientations, all identities, standing firm in inclusiveness, affirmation, diversity.
                    <br /><br />So, if you are curious in LGBT issues, or world religions and fringe faiths, or feel left out in community because of your identity, or looking to explore a place where we celebrate You for being yourself, or if you like playing board games, video games, or engage in pop culture, YouYou is definitely here for you! 
                    <br /><br />Love, YouYou facilitator
                    </p>
                </div>

                {/* row */}
                <div className='col-span-1 md:col-span-2 font-sylexiad text-center'>
                    <StaticImage src='../images/YouYouQuickIntro.webp' 
                            alt="(card 1) YouYou Quick Introduction. What is YouYou? YouYou is a community committed to the safe space of the religious minority and the LGBTQIA+. (card 2) What is a safe space? A place where its members can feel safe. Safe space is ensured with rules during sessions, and with its welcome policy, where entry and membership are decided by members and not automatic. (card 3) What are safe space rules? 1. Confidentiality. Identities and narratives shared here must require permission to be shared elsewhere. 2. Respect. The Golden Rule. Respect each other as you wish others to respect you. 3. Growth. One for all, all for one. We are all in this together, No member is left behind. (card 4) General good respect guidelines: - One conversation a time - Ask permission before interrupting - Dont talk over someone - Active listening: eye contact, response cues, less phone time (card 5) Growth attitudes and guidelines: - Leave space for others and yourself - Be willing to challenge and be challenged - Vulnerability progresses with trust - Object without antagonism (Card 6) But what is this safe space for specfically? - LGBTQIA+ Issues - Faith and religious trauma, persecution, exploration, open sharing of spiritual leaving and journeys - The general well-being. (card 7) So, when you or others are relating experiences: - Suspend Judgment (even the good ones) - Validate Experience (trust in the speaker) - Take time to listen, clarify, speak and understand. (card 8) Because our YouYou sessions aim to: - Be individually beneficial every time - Have members feel secure and free - Develop a continued genuine interest in each other's lives"
                            className='rounded-md shadow-lg' />
                    <p>Order from top left to top right, then bottom left to bottom right</p>
                </div>

                {/* row */}
                <div className='col-span-1 md:col-span-2 font-sylexiad'>
                    <h2 className='title' id='safe-space-guidelines'>What is a safe space? (And how to conduct one)</h2>
                    {SAFE_SPACE_DEFINITIONS.map(s => (
                        <div key={s.title} className='mt-3 mb-3'>
                            <h3 className='subtitle font-sylexiad-bold text-2xl'>{s.title}</h3>
                            <p className='text text-xl' dangerouslySetInnerHTML={{__html: s.body}}></p>
                        </div>
                    ))}
                    <h3 className='subtitle font-sylexiad-bold text-2xl'>In general, safe space guidelines should incorporate:</h3>
                    <ul className='statement-list text-xl ml-4'>
                        <li>Practices that increase safe peer group interaction, and decrease unsafe or confrontational peer interactions. </li>
                        <li>Mental and physical safety from harassment, bullying, violence, put-downs, etc.</li>
                        <li>Respect for others; non-judgmental attitudes, constructive feedback; inclusiveness; and sensitivity to culture, ethnicity, nationality, sexuality, gender, and other backgrounds. (Different points of view)</li>
                    </ul>
                </div>

                {/* row */}
                <div className='col-span-1 md:col-span-2 font-sylexiad text-center justify-center align-center flex relative'>
                    <div className='relative'>
                        <img src={image1} alt='People on a hill' className='my-auto mx-auto rounded-md shadow-lg'/>
                        <Link to='#policies'>
                            <div className='text bg-black my-auto absolute top-5 right-10 left-10 md:left-60 md:-right-40 md:top-10 border-2 border-black rounded-md'>
                                <strong className='font-sylexiad-bold text-white'>Our Policies &#8595;</strong>
                                <p className='text text-gray-200 text-base'>Learn more about our policies below.</p>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* row */}
                <div className='col-span-1 font-sylexiad mt-5'>
                    <h2 className='title' id='policies'>Our Policies</h2>
                    <h3 className='subtitle font-sylexiad-bold text-2xl'>Welcome Policy</h3>
                    <ol className='statement-list list-decimal list-outside ml-5 text-xl'>
                        <li>Everyone should follow safe space rules. (See <Link to='#safe-space-guidelines' className='underline'>Safe Space Rules</Link>)</li>
                        <li>Newcomers are screened on a case by case basis.</li>
                        <li>All members of YouYou can welcome and invite.</li>
                        <li>For unfamiliar newcomers, facilitators will make the final decision.</li>
                        <li>All members of YouYou can veto, but must provide a good reason to do so.</li>
                    </ol>
                    <br />
                    <h3 className='subtitle font-sylexiad-bold text-2xl'>Commitment Policy</h3>
                    <ol className='statement-list list-decimal list-outside ml-5 text-xl'>
                        <li>Elle and Mel will be current facilitators.</li>
                        <li>All members not obligated to come weekly.</li>
                        <li>Don't apologize for absence.</li>
                        <li>There are always other ways to participate.</li>
                        <li>We will always try to include everyone, and,</li>
                        <li>We are always happy to see You in YouYou :)</li>
                    </ol>
                    <br />
                    <h3 className='subtitle font-sylexiad-bold text-2xl'>Safe Space Moderator Description</h3>
                    <ol className='statement-list list-decimal list-outside ml-5 text-xl'>
                        <li>Someone to uphold safe space principles.</li>
                        <li>Able to issue reminders to members in case they don't keep to safe space environment.</li>
                        <li>Moderator's actions and judgments are screened by all other members.</li>
                    </ol>
                </div>

                <div className='col-span-1 font-sylexiad md:mt-14'>
                    <h3 className='subtitle font-sylexiad-bold text-2xl'>Circle of Confidentiality in YouYou</h3>
                    <ol className='statement-list list-decimal list-outside ml-5 text-xl'>
                        <li>All YouYou group spaces will be viewed as a single unified safe space. (So far, this includes only two areas: the weekly physical meetings, and the whatsapp chatgroup. Facebook group and socials are not counted as a safe space.)</li>
                        <li>As thus, YouYou will have the same safe space principles, same membership, and similar guidelines for all areas.</li>
                        <li>Our circle of confidentiality will be bounded by membership and not by individual platforms.</li>
                        <li>When in a safe space, if a member wishes to share in confidence of selected members only, this is allowable, but not expected (must be said explicitly) and not enforceable (by the moderator). Selective confidentiality is not a standard in YouYou.</li>
                        <li>All rules above do not affect private conversations between members. It is still up to the freedom and consent of concerning parties. Privacy from non members is still respected.</li>
                        <li>Elle is the current moderator of YouYou safe space.</li>
                    </ol>
                </div>

                {/* row */}
                <div className='col-span-1 md:col-span-2 font-sylexiad text-center mt-2 md:mt-5'>
                    <Link to='/contact'><button className='general-button'>Visit our session!</button></Link>
                </div>

                <div className='col-span-1 md:col-span-2 text-center'>
                        <StaticImage src='../images/image-asset2.webp'
                                    alt='Pride reflected in water'
                                    className='rounded-md shadow-lg'/>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs;